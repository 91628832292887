import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useCookies from './useCookies';
import './cookieconsent.css';

export const CookieConsent = ({ lang }) => {
  const { t } = useTranslation();
  const {
    modalRef,
    customRef,
    data,
    handleAccept,
    handleDecline,
    customSelected,
    setCustomSelected,
    handleCustomSelect
  } = useCookies({ lang });
  const { title, body, link, link_text, accept, deny, custom } = data || [];

  const dialogClass = `privacypolicies-com---reset privacypolicies-com---nb privacypolicies-com---palette-dark ${
    customSelected ? 'privacypolicies-com---nb-centered' : 'privacypolicies-com---nb-interstitial'
  } privacypolicies-com---lang-es`;

  const privacypolicies_com___nb_interstitial = {
    width: '97%',
    maxWidth: '1200px',
    position: 'fixed',
    height: 'max-content',
    padding: '0px',
    top: 'auto',
    bottom: '16px'
  };
  return (
    <>
      <dialog
        ref={modalRef}
        className={dialogClass}
        style={customSelected ? privacypolicies_com___nb_interstitial : null}
      >
        <div
          className={
            'cc-nb-main-container' + (customSelected ? ' cc-nb-main-container_helper' : '')
          }
        >
          {title && (
            <div className="cc-nb-title-container">
              <p className="cc-nb-title">{title}</p>
            </div>
          )}
          <div className="cc-nb-text-container" style={customSelected ? { width: '100%' } : null}>
            {customSelected ? (
              <section className="section-container" ref={customRef}>
                <h2 className="font-lg-m" id="ot-category-title">
                  {t('Gestionar las preferencias')}
                </h2>
                {data.cookies.map((cookie, index) => (
                  <Fragment key={index}>
                    <details
                      key={index}
                      name="cookie_info"
                      className="ot-accordion-layout ot-cat-item ot-vs-config"
                      data-optanongroupid={`C000${index + 1}`}
                    >
                      <summary className="m-t-16">
                        <div
                          className="ot-acc-hdr"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '-webkit-fill-available'
                          }}
                        >
                          <h6 className="ot-cat-header font-s font-lg-xs">{cookie.title}</h6>

                          <input
                            className="react-switch-checkbox"
                            name={cookie.title}
                            id={cookie.id}
                            type="checkbox"
                            role="switch"
                            tabIndex="0"
                            defaultChecked={cookie.active === 'always'}
                            disabled={cookie.active === 'always'}
                          />
                          <label className="react-switch-label" htmlFor={cookie.id}>
                            <span className="react-switch-button">
                              <span style={{ display: 'none' }}>{cookie.title}</span>
                            </span>
                          </label>
                        </div>
                      </summary>
                      <div className="ot-acc-grpcntr ot-acc-txt m-t-8">
                        <p
                          className="ot-acc-grpdesc ot-category-desc font-xs c-greyone"
                          id={`ot-desc-id-C000${index + 1}`}
                        >
                          {cookie.description}
                        </p>
                      </div>
                    </details>
                    <div className="separator  m-t-24" />
                  </Fragment>
                ))}
              </section>
            ) : (
              <p className="cc-nb-text font-xs c-black">
                {body}
                <a className="c-black" href={link} target="_blank" rel="noopener noreferrer">
                  {` ${link_text}`}
                </a>
              </p>
            )}
          </div>
          <div
            className="cc-nb-buttons-container"
            style={{
              flexWrap: customSelected ? 'wrap' : 'nowrap'
            }}
          >
            {!customSelected && (
              <button
                className="cc-nb-custom c-h-secundary font-s"
                onClick={() => setCustomSelected(true)}
              >
                {custom}
              </button>
            )}
            <button className="cc-nb-reject font-s c-h-white bc-h-sendity" onClick={handleDecline}>
              {deny}
            </button>
            <button
              className="cc-nb-okagree font-s bc-h-secundary"
              onClick={() => (customSelected ? handleCustomSelect() : handleAccept())}
            >
              {customSelected ? t('Guardar Configuración') : accept}
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
};
