import { useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import config from '../../config/config';
const langObj = {
  es: {
    title: '',
    body: '¿Aceptas las cookies? Te ayudarán a tener una mejor y personalizada experiencia con Sendity. Obtén más información en nuestra ',
    link: '/politica-cookies',
    link_text: 'Política de Cookies',
    accept: 'Acepto todas las cookies',
    deny: 'Renuncio',
    custom: 'Gestionar',
    cookies: [
      {
        title: 'Cookies de técnicas y de rendimiento (Necesarias):',
        active: 'always',
        id: 'mandatory_cookies',
        description:
          'Permiten navegar por SENDITY y utilizar sus servicios o funciones, como por ejemplo acceder a zonas tu cuenta, realizar transacciones, realizar el proceso de inscripción, etc.'
      },
      {
        title: 'Cookies analíticas',
        active: '',
        id: 'performance_cookies',
        description:
          'Son aquellas que, tratadas por SENDITY o por terceros, nos permiten cuantificar el número de usuarios y así realizar el análisis de la utilización se hace de nuestra web. Se analiza su navegación en nuestra web con el fin de mejorar la oferta de productos o servicios que ofrecemos.'
      },
      {
        title: 'Cookies de publicidad',
        active: '',
        id: 'ad_cookies',
        description:
          'Usamos estas cookies para brindarte publicidad pertinente y medir la eficencia de nuestras campañas de anuncios.'
      }
    ]
  },
  en: {
    title: '',
    body: 'Are you ok with cookies? They will help you have a better experience on our page. Read more about our',
    link: '/en/cookies-policy',
    link_text: 'Cookies Policy',
    accept: 'Accept',
    deny: 'Refuse',
    custom: 'Filter cookies',
    cookies: [
      {
        title: 'Technical and performance cookies (Necessary)',
        active: 'always',
        id: 'mandatory_cookies',
        description:
          'They allow you to browse SENDITY and use its services or functions, such as accessing your account, making transactions, completing the registration process, etc'
      },
      {
        title: 'Analytical cookies',
        active: '',
        id: 'performance_cookies',
        description:
          'These are cookies that, when processed by SENDITY or by third parties, allow us to quantify the number of users and thus analyze the use made of our website. Your browsing on our website is analyzed in order to improve the offer of products or services that we offer.'
      },
      {
        title: 'Advertising cookies',
        active: '',
        id: 'ad_cookies',
        description:
          'We use these cookies to provide you with relevant advertising and to measure the effectiveness of our advertising campaigns.'
      }
    ]
  }
};

const cookiesGroup = {
  mandatory_cookies: 'functionality_storage, personalization_storage, security_storage',
  performance_cookies: 'analytics_storage',
  ad_cookies: 'ad_storage, ad_user_data, ad_personalization'
};

const mandatoryCookiesCount = cookiesGroup.mandatory_cookies.split(', ').length;

const useCookies = ({ lang }) => {
  const modalRef = useRef();
  const customRef = useRef();

  const [customSelected, setCustomSelected] = useState(false);

  const isSendityCookieUp = !!new Cookies().get('sendity-com');

  const hasUserGivenConsent = typeof document !== 'undefined' ? isSendityCookieUp : 'waiting';

  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  const data = langObj[lang];

  useEffect(() => {
    if (hasUserGivenConsent === 'waiting') return;
    const consentCookiesUpToBeGranted = () => {
      const cookieData = new Cookies().get('sendity-com');

      if (!cookieData) return false;

      const grantedCount = Object.values(cookieData).filter(value => value === 'granted').length;
      return grantedCount === mandatoryCookiesCount;
    };

    const cookiesUpToGenerate = consentCookiesUpToBeGranted();

    const prevConsentTypeCookie = new Cookies().get('sendity-com_consent');

    const prevConsentValueCookie = new Cookies().get('sendity-com=');

    if (!!prevConsentTypeCookie && !!prevConsentValueCookie) {
      const consent = JSON.parse(prevConsentValueCookie);

      gtag('consent', 'update', { ...consent });
    }

    if (cookiesUpToGenerate && !prevConsentTypeCookie) {
      modalRef?.current?.showModal();
    }
  }, [hasUserGivenConsent]);

  const generateCookie = ({ consent, type }) => {
    gtag('consent', 'update', { ...consent });
    const date = new Date();
    const days = 90;
    const expires = new Date(date.getTime() + days * 24 * 60 * 60 * 1000); // Calculate expiration date

    const findCookie = new Cookies();
    // Get existing cookies
    // const sendityCookie = findCookie.get("sendity-com")

    const sendityCookie_consent = findCookie.get('sendity-com_consent');
    // for testing
    // findCookie.set('sendity-com', consent, {
    //   domain: '.sendity-test.com',
    //   sameSite: 'strict',
    //   expires,
    //   secure: true
    // });

    // // Create the consent cookie if it doesn't exist
    // if (!sendityCookie_consent) {
    //   findCookie.set('sendity-com_consent', type, {
    //     domain: '.sendity-test.com',
    //     sameSite: 'strict',
    //     expires,
    //     secure: true
    //   });
    // }

    // Update existing cookie or create a new one
    findCookie.set('sendity-com', consent, {
      domain: '.sendity.com',
      sameSite: 'strict',
      expires,
      secure: true
    });

    // Create the consent cookie if it doesn't exist
    if (!sendityCookie_consent) {
      findCookie.set('sendity-com_consent', type, {
        domain: '.sendity.com',
        sameSite: 'strict',
        expires,
        secure: true
      });
    }
  };

  const closeAndGenerate = ({ consent, type }) => {
    generateCookie({ consent, type });
    modalRef.current.close();
  };

  const handleAccept = () => {
    const consentObj = {
      functionality_storage: 'granted',
      security_storage: 'granted',
      personalization_storage: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted'
    };

    closeAndGenerate({ consent: consentObj, type: 'accept' });
  };

  const handleDecline = () => {
    const consentObj = {
      functionality_storage: 'granted',
      security_storage: 'granted',
      personalization_storage: 'granted',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied'
    };

    closeAndGenerate({ consent: consentObj, type: 'decline' });
  };

  const handleCustomSelect = () => {
    const selectedInputs = customRef.current.getElementsByTagName('input');
    const inputArray = Array.from(selectedInputs);

    const filterInputsByName = inputArray.filter(({ checked }) => checked).map(({ id }) => id); // Store only the IDs of checked checkboxes

    const consentObj = {
      functionality_storage: 'denied',
      security_storage: 'denied',
      personalization_storage: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied'
    };

    // Use map to iterate over each key in consentObj and update its value if its key matches any checked checkbox ID
    Object.keys(consentObj).forEach(key => {
      Object.keys(cookiesGroup).forEach(groupKey => {
        const cookies = cookiesGroup[groupKey].split(', ');
        if (cookies.includes(key) && filterInputsByName.includes(groupKey)) {
          consentObj[key] = 'granted';
        }
      });
    });

    closeAndGenerate({ consent: consentObj, type: 'custom' });
  };

  return {
    modalRef,
    customRef,
    data,
    handleAccept,
    handleDecline,
    customSelected,
    setCustomSelected,
    handleCustomSelect
  };
};

export default useCookies;
